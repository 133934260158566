<template>
  <div class="seal-equipment-wrapper">
    <div :class="['seal-equipment',{'mobile-content':clientWidth<=mobileWidth}]">
      <div class="title">印章设备</div>
      <div class="img-content top-img-content" v-if="clientWidth<=mobileWidth">
        <div class="item" @click="toShowDialog">
          <div class="img-wapper first-img">
            <img :src="require(`../../../assets/imgs/sealingEquipment/jgdkj@2x.png`)">
          </div>
          <p>激光雕刻机</p>
        </div>
      </div>
      <div class="img-content">
        <div class="item" v-if="clientWidth>mobileWidth" @click="toShowDialog">
          <div class="img-wapper first-img">
            <img :src="require(`../../../assets/imgs/sealingEquipment/jgdkj@2x.png`)"
                 class="">
          </div>
          <p>激光雕刻机</p>
        </div>
        <div :class="['item', {'white-item':clientWidth>mobileWidth}]" @click="toShowDialog">
          <p v-if="clientWidth>mobileWidth">制版机</p>
          <div class="img-wapper second-img">
            <img :src="require(`../../../assets/imgs/sealingEquipment/zbj@2x.png`)">
          </div>
          <p v-if="clientWidth<=mobileWidth">制版机</p>
        </div>
        <div class="item" @click="toShowDialog">
          <div class="img-wapper third-img">
            <img :src="require(`../../../assets/imgs/sealingEquipment/ryj@2x.png`)">
          </div>
          <p>热压机</p>
        </div>
      </div>
    </div>

    <apply-dialog ref="applyDialog" :clientWidth="clientWidth"/>
  </div>
</template>

<script>
import ApplyDialog from '@components/ApplyDialog.vue';

export default {
  name: 'SealingEquipment',
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  components: {
    ApplyDialog,
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  methods: {
    toShowDialog() {
      this.$refs.applyDialog.openDialog();
    },
  },
};
</script>

<style lang="less" scoped>
.seal-equipment {
  margin: 130px 0 254px;

  .title {
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    position: relative;
    text-align: center;
    margin-bottom: 95px;

    &::after {
      position: absolute;
      content: '';
      width: 120px;
      height: 8px;
      background: #F75959;
      border-radius: 4px;
      bottom: -24px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .img-content {
    display: flex;
    justify-content: center;
    align-items: center;

    .item {
      width: 400px;
      height: 600px;
      display: flex;
      flex-direction: column;
      background: #F5F5F5;

      p {
        text-align: center;
        margin: 66px auto 66px;
        font-size: 36px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
      }

      .img-wapper {
        margin: auto 0;
        position: relative;

        &::after {
          position: absolute;
          width: 100%;
          height: 64px;
          background: #F75959;
          content: '';
          left: 0;
          bottom: 2px;
          z-index: 1;
        }

        img {
          display: block;
          margin: 0 auto;
          position: relative;
          z-index: 2;
        }
      }

      .first-img {
        img {
          width: 348px;
          height: 185px;
        }
      }

      .second-img {
        img {
          width: 217px;
          height: 357px;
        }

        &::after {
          bottom: 47px;
        }
      }

      .third-img {
        img {
          width: 268px;
          height: 334px;
        }

        &::after {
          bottom: 40px;
        }
      }
    }

    .white-item {
      background: none;
    }
  }
}

.mobile-content {
  margin: 110px auto;

  .title {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.85);
    line-height: 36px;
    margin-bottom: 40px;

    &::after {
      width: 60px;
      bottom: -5px;
      height: 4px;
    }
  }

  .img-content {
    justify-content: space-between;
    padding: 0 37px;

    .item {
      width: 140px;
      height: 210px;

      .img-wapper {
        &::after {
          height: 22.4px;
        }
      }

      p {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 22px;
        margin: 0 auto 16px;
      }

      .first-img {
        img {
          width: 122px;
          height: 65px;
        }
      }

      .second-img {
        img {
          width: 76px;
          height: 125px;
        }
      }

      .third-img {
        img {
          width: 94px;
          height: 117px;
        }
      }
    }
  }

  .top-img-content {
    justify-content: center;
    margin-bottom: 20px;
  }
}
</style>
